<template>
	<div class="container" v-bind:class="{'full-width':option.fullWidth}">
		<div class="item">
			<img class="icon" mode="aspectFit" :src="icon" v-bind:class="{'clickable':option.switchIndex>0}" @click="clickIcon"></img>
			<div class="value-content" v-bind:class="{clickable:option.writable}" @click="clickValue">
				<div class="name">{{option.name}}</div>
				<div class="value-row">
					<div class="value">{{value}}</div>
					<div class="unit">{{option.unit}}</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import ParasUtil from "./ParasUtil.js"
	export default {
		props:["iotId","option","valueMap","value","valueFormatter"],

		methods:{
			clickIcon(){
				if(!this.option.switchIndex){
					return
				}
				let index = this.option.switchIndex
				let value = this.valueMap[index+""] || 0
				let items = {}
				items[index]=value>0?0:1
				ParasUtil.controlFunction(items)
			},
			clickValue(){
				if(!this.option.writable){
					return
				}
				let value = this.valueMap[this.option.index+""] || 0
				ParasUtil.showDialog({...this.option,value,placeholder:this.value})


			},

		},
		computed:{
			icon(){
				if(this.option.switchIndex && this.option.switchIndex>0 && this.switchValue && this.switchValue>0){
					return this.option.selectedIcon || this.option.icon
				}else{
					return this.option.icon
				}
			},
			switchValue(){
				return this.valueMap[this.option.switchIndex+""] || 0
			},
			getValue(){
				let value = this.valueMap[this.option.index+""] || 0
				let switchValue = this.valueMap[this.option.switchIndex+""] || 0

				return this.option.valueFormatter+""

				if(this.option.valueFormatter){
					return this.option.valueFormatter(switchValue,value)
				}else{
					return value
				}

			}
		}
	}
</script>

<style scoped lang="scss">
	.container{
		width: 16.66%;
		float: left;

		.item{
			background: #FFF;
			margin: 3px;
			display: flex;
			align-items: center;
			padding: 5px;
			border-radius: 5px;

		}

		.icon{
			width: 35px;
			height: 35px;
			padding: 5px;
		}

		.name{
			font-size: 12px;
			color: #999999;
			margin-top: 5px;
		}

		.value-content{
			padding-left: 10px;
			flex: 1;
		}

		.clickable:active{
			background: #CCC;
			border-radius: 10px;
		}

		.value-row{
			display: flex;
			align-items: baseline;
		}

		.value{
			font-size: 17px;
			color: #333333;
		}

		.unit{
			font-size: 11px;
			color: #666666;
			padding-left: 5px;
			margin-bottom: 2px;
		}
	}

	.full-width{
		/*width: 33.33%;*/
	}

</style>
